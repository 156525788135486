import axios from "axios";
import Document from "../types/document";
import Cookies from "universal-cookie";

const ifaApiUrl =
  process.env.REACT_APP_IFA_API_URL || "https://ifa-uat.novex.software";

const addDocument = async (document: Document) => {
  const token = new Cookies().get("portal-token");

  try {
    const response = await axios.post(`${ifaApiUrl}/documents`, document, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

export default addDocument;
