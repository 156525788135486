import axios from "axios";
import Cookies from "universal-cookie";

const addNote = async (fileId: number, content: string) => {
  try {
    const response = await axios.post(
      `https://ifa-uat.novex.software/notes`,
      {
        ifa: new Cookies().get("username"),
        file_id: fileId.toString(),
        content: content,
      },
      {
        headers: {
          Authorization: `Bearer ${new Cookies().get("portal-token")}`,
        },
      }
    );

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export default addNote;
