import {
  Box,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  LinearProgress,
  Link,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import getUpdates from "../../utils/getUpdates";
import getMatterData from "../../utils/getMatterData";
import matterData from "../../types/matterData";
import { DataGridPro, GridColDef, GridToolbar } from "@mui/x-data-grid-pro";
import Note from "../../types/note";
import getNotes from "../../utils/getNotes";
import Cookies from "universal-cookie";
import addNote from "../../utils/addNote";
import { useDropzone } from "react-dropzone";
import presignUpload from "../../utils/presignUpload";
import upload from "../../utils/upload";
import addDocument from "../../utils/addDocument";
import Document from "../../types/document";
import getClassifications from "../../utils/getClassifications";

var sleepSetTimeout_ctrl: any;

function sleep(ms: number) {
  clearInterval(sleepSetTimeout_ctrl);
  return new Promise(
    (resolve) => (sleepSetTimeout_ctrl = setTimeout(resolve, ms))
  );
}

const textStyle = {
  fontSize: 12,
  color: "#8199A6",
};

const Cases = (): JSX.Element => {
  const [updates, setUpdates] = React.useState<Update[]>([]);
  const [matterData, setMatterData] = React.useState<matterData[]>([]);
  const [activeMatter, setActiveMatter] = React.useState<matterData | null>();
  const [notes, setNotes] = React.useState<Note[]>([]);
  const [tab, setTab] = React.useState<number>(0);
  const [addingComment, setAddingComment] = React.useState<boolean>(false);
  const [newComment, setNewComment] = React.useState<string>("");
  const [files, setFiles] = React.useState<File[]>([]);
  const [classifications, setClassifications] = React.useState<string[]>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
    for (const file of acceptedFiles) {
      uploadFile(file);
    }
  }, []);

  const uploadFile = async (file: File) => {
    const response = await presignUpload(
      activeMatter?.FileID?.toString() || "17",
      file.name,
      file.type
    );

    await upload(file, response.presigned_url);

    const documentResponse = await addDocument({
      file_id: activeMatter?.FileID?.toString() || "17",
      content: file.name,
      custom_field: response.document_url,
      type: "file",
      username: new Cookies().get("username"),
    } as Document);

    setClassifications([documentResponse.answer || ""]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const username = new Cookies().get("username");

  const escFunction = useCallback((event: any) => {
    if (event.key === "Escape") {
      setAddingComment(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    loadUpdates();
    loadMatterData();
  }, []);

  const loadUpdates = async () => {
    const updates = await getUpdates();

    setUpdates(updates);
  };

  const loadMatterData = async () => {
    const md = await getMatterData();

    setMatterData(md);
  };

  const loadNotes = async (fileId: number) => {
    const notes = await getNotes(fileId);

    setNotes(notes);
  };

  const addComment = async () => {
    await addNote(activeMatter?.FileID || 0, newComment);
    loadNotes(activeMatter?.FileID || 0);
  };

  const columns: GridColDef[] = [
    {
      field: "Reference",
      headerName: "Case number",
      flex: 0.2,
    },
    {
      headerName: "Client",
      field: "",
      valueGetter: (params) => {
        return `${params.row.FirstName} ${params.row.Surname}`;
      },
      flex: 0.2,
    },
    {
      field: "MatterType",
      headerName: "Matter type",
      flex: 0.2,
    },
    {
      field: "CaseHandler",
      headerName: "Contact",
      flex: 0.2,
    },
    {
      field: "CurrentStatus",
      headerName: "Status",
      flex: 0.2,
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography fontSize={16} fontWeight="bold">
            Updates{" "}
            <Link
              fontWeight={"normal"}
              component="button"
              color="rgba(222, 134, 70, 1);"
            >
              (view all)
            </Link>
          </Typography>
          <Box marginTop={2}>
            <Grid container spacing={1}>
              {updates.length === 0 ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <>
                  {updates?.map((update, index) => {
                    const date = new Date(update.milestoneDate);
                    return (
                      <Grid key={index} item xs={6} md={2.4}>
                        <Card>
                          <CardContent>
                            <Typography
                              color="#8199A6"
                              fontSize={12}
                            >{`${String(date.toLocaleDateString()).padStart(
                              2,
                              "0"
                            )} ${date.getHours()}:${String(
                              date.getMinutes()
                            ).padStart(2, "0")}`}</Typography>
                            <Typography
                              marginTop={1}
                              fontWeight={"bold"}
                              color="#8199A6"
                            >
                              {update.reference}
                            </Typography>
                            <Typography color="#8199A6">
                              {update.milestone}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
          </Box>
          {matterData.length > 0 && (
            <Box bgcolor="white" marginTop={5} width="100%" height="48vh">
              <DataGridPro
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                density="compact"
                getRowId={(row: matterData) => {
                  return row.Reference || 0;
                }}
                pagination
                columns={columns}
                rows={matterData}
                autoPageSize
                onRowClick={(params) => {
                  const matterData = params.row as matterData;
                  setActiveMatter(matterData);
                  loadNotes(matterData.FileID || 0);
                }}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Box marginLeft={5} height="100%" bgcolor={"white"}>
            {activeMatter ? (
              <Box>
                <Box borderBottom={1} borderColor={"#E6EBED"}>
                  <Tabs
                    value={tab}
                    onChange={(e, val) => {
                      setTab(val);
                    }}
                  >
                    <Tab sx={{ textTransform: "none" }} label="Case info" />
                    <Tab
                      sx={{ textTransform: "none" }}
                      label={`Comments (${notes?.length || 0})`}
                    />
                    <Tab sx={{ textTransform: "none" }} label="Documents" />
                  </Tabs>
                </Box>
                {tab === 0 && (
                  <>
                    <Box padding={2} borderBottom={1} borderColor={"#E6EBED"}>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Typography sx={textStyle}>Client Name:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography sx={textStyle}>
                            {`${activeMatter.FirstName} ${activeMatter.Surname}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={textStyle}>Address:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography sx={textStyle}>
                            {activeMatter.Address1}
                          </Typography>
                          <Typography sx={textStyle}>
                            {activeMatter.Address2 || ""}
                          </Typography>
                          <Typography sx={textStyle}>
                            {activeMatter.Town}
                          </Typography>
                          <Typography sx={textStyle}>
                            {activeMatter.County}
                          </Typography>
                          <Typography sx={textStyle}>
                            {activeMatter.Postcode}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={textStyle}>Phone:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography sx={textStyle}>
                            {`${activeMatter.Telephone}`}
                          </Typography>
                          <Typography sx={textStyle}>
                            {`${activeMatter.Mobile}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={textStyle}>Email:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography sx={textStyle}>
                            {`${activeMatter.Email}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box padding={2} borderBottom={1} borderColor={"#E6EBED"}>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Typography sx={textStyle}>Contact:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography sx={textStyle}>
                            {`${activeMatter.CaseHandler}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={textStyle}>Phone:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography sx={textStyle}>
                            {`${activeMatter.CaseHandlerDDI}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={textStyle}>Email:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography sx={textStyle}>
                            {`${activeMatter.CaseHandlerEmail}`}
                          </Typography>
                          <Link
                            fontSize={12}
                            fontWeight={"normal"}
                            component="button"
                            color="rgba(222, 134, 70, 1);"
                          >
                            book a call
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
                {tab === 1 && (
                  <>
                    <Box padding={2}>
                      <Box
                        borderRadius={"50%"}
                        marginRight={2}
                        marginBottom={1}
                        height={33}
                        width={33}
                        color="white"
                        bgcolor={"#04334E"}
                        textAlign="center"
                        display="inline-block"
                        sx={{ verticalAlign: "middle", lineHeight: "33px" }}
                      >
                        {username[0].toUpperCase()}
                      </Box>
                      {addingComment ? (
                        <>
                          <TextField
                            value={newComment}
                            onChange={(e) => {
                              setNewComment(e.target.value);
                            }}
                            sx={{ width: "80%" }}
                            size="small"
                            variant="filled"
                            label="Add a comment..."
                            multiline
                          />
                          <Link
                            sx={{
                              marginLeft: 1,
                              float: "right",
                              marginTop: 1,
                              marginRight: 2,
                            }}
                            component="button"
                            fontSize={"small"}
                            onClick={() => {
                              addComment();
                              setAddingComment(false);
                              setNewComment("");
                            }}
                            color="rgba(222, 134, 70, 1);"
                          >
                            Submit
                          </Link>
                        </>
                      ) : (
                        <Chip
                          onClick={() => {
                            setAddingComment(true);
                          }}
                          label="Add a comment..."
                          sx={{
                            marginBottom: 1,
                            width: "80%",
                            alignContent: "left",
                          }}
                        />
                      )}
                      <Box marginTop={2}>
                        {notes?.map((note, index) => {
                          const date = new Date(note.updated_at);
                          return (
                            <Box
                              key={index}
                              marginBottom={2}
                              borderBottom={1}
                              borderColor={"#8199A6"}
                            >
                              <Box
                                borderRadius={"50%"}
                                marginRight={2}
                                marginBottom={1}
                                height={33}
                                width={33}
                                color="white"
                                bgcolor={"#04334E"}
                                textAlign="center"
                                display="inline-block"
                                sx={{
                                  verticalAlign: "middle",
                                  lineHeight: "33px",
                                }}
                              >
                                {`${note.last_edited_by[0].toUpperCase()}`}
                              </Box>
                              <Typography
                                sx={{ display: "inline-block" }}
                                fontWeight={"bold"}
                                textTransform={"capitalize"}
                              >
                                {note.last_edited_by
                                  .split("@")[0]
                                  .split(".")
                                  .join(" ")}
                              </Typography>
                              <Typography
                                color="#8199A6"
                                marginLeft={3}
                                sx={{ display: "inline-block" }}
                              >{`${String(date.toLocaleDateString()).padStart(
                                2,
                                "0"
                              )} ${date.getHours()}:${String(
                                date.getMinutes()
                              ).padStart(2, "0")}`}</Typography>
                              <Typography
                                color="#8199A6"
                                marginLeft={6.2}
                                marginBottom={2}
                              >
                                {note.content}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </>
                )}
                {tab === 2 && (
                  <Box padding={2}>
                    {files?.length === 0 ? (
                      <Box
                        {...getRootProps()}
                        sx={{ borderStyle: "dashed", borderColor: "#E6EBED" }}
                        border={2}
                        padding={4}
                      >
                        <input
                          data-testid="file-input"
                          hidden
                          {...getInputProps()}
                        />
                        <Typography color="#8199A6">
                          Drag your documents here or{" "}
                          <Link
                            color="rgba(222, 134, 70, 1);"
                            sx={{ cursor: "pointer" }}
                          >
                            browse files
                          </Link>
                          .
                        </Typography>
                      </Box>
                    ) : (
                      <Box padding={2}>
                        <Typography fontWeight="bold">
                          Adding supporting documents
                        </Typography>
                        <Box marginTop={2}>
                          {files?.map((file, index) => {
                            return (
                              <Box key={index} marginTop={2}>
                                <Typography sx={{ display: "inline-block" }}>
                                  {file.name}
                                </Typography>
                                <Box marginTop={2}>
                                  {classifications.length > 0 ? (
                                    <>
                                      <Typography color="#8199A6">{`We have identified the following item(s) in your uploaded file: `}</Typography>
                                      <Box marginTop={2}>
                                        {classifications.map((cfn, index) => {
                                          return (
                                            <Typography
                                              key={index}
                                              fontWeight={"bold"}
                                            >
                                              {cfn}
                                            </Typography>
                                          );
                                        })}
                                      </Box>
                                      <Typography sx={{ marginTop: 2 }}>
                                        Think this is wrong?{" "}
                                        <Link
                                          component="button"
                                          fontSize={"small"}
                                          onClick={() => {
                                            setFiles([]);
                                          }}
                                          color="rgba(222, 134, 70, 1);"
                                        >
                                          Click here to tell us
                                        </Link>
                                      </Typography>
                                    </>
                                  ) : (
                                    <Box>
                                      <Typography>
                                        We are currently classifying your
                                        documents... this may take a minute!
                                      </Typography>{" "}
                                      <CircularProgress sx={{ marginTop: 2 }} />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                        <Link
                          component="button"
                          fontSize={"small"}
                          onClick={() => {
                            setFiles([]);
                          }}
                          color="rgba(222, 134, 70, 1);"
                          sx={{ marginTop: 2 }}
                        >
                          Cancel
                        </Link>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            ) : (
              <Box textAlign={"center"}>
                <Typography color="#8199A6" padding={5}>
                  Select a case on the left to see details about it here.
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Cases;
