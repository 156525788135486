import React from "react";

import {
  AppBar,
  Box,
  Divider,
  Link,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import { svgimages } from "../../utils/svgimages";
import Cookies from "universal-cookie";

const TopBar = ({ username }: { username: string }): JSX.Element => {
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        sx={{ bgcolor: "white", color: "black", minHeight: "100px" }}
        component="nav"
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <img src={svgimages["equilaw-logo"]} alt="Equilawlogo1651" />
          </Box>
          <Box
            sx={{
              textAlign: "right",
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
            }}
          >
            <Box marginTop={1} display={"block"}>
              <Box
                borderRadius={"50%"}
                marginRight={2}
                marginBottom={1}
                height={33}
                width={33}
                color="white"
                bgcolor={"#04334E"}
                textAlign="center"
                display="inline-block"
                sx={{ verticalAlign: "middle", lineHeight: "33px" }}
              >
                {username[0].toUpperCase()}
              </Box>
              <Typography display="inline-block">{username}</Typography>
              <Divider
                sx={{ marginLeft: 2, display: "inline-block" }}
                orientation="vertical"
                flexItem
              />
              <Link
                sx={{ marginLeft: 3 }}
                component="button"
                fontSize={"small"}
                onClick={() => {}}
              >
                Help
              </Link>
              <Link
                sx={{ marginLeft: 3 }}
                component="button"
                fontSize={"small"}
                onClick={() => {}}
              >
                Settings
              </Link>
              <Link
                sx={{ marginLeft: 3 }}
                component="button"
                fontSize={"small"}
                onClick={() => {
                  new Cookies().remove("portal-token");
                  new Cookies().remove("username");
                  window.location.reload();
                }}
              >
                Log out
              </Link>
            </Box>

            <Box display={"block"}>
              <Tabs
                TabIndicatorProps={{
                  sx: {
                    height: 5,
                  },
                }}
                value={0}
                style={{ float: "right" }}
              >
                <Tab
                  sx={{ textTransform: "none", fontSize: 14 }}
                  label="Your cases"
                />
                <Tab
                  sx={{ textTransform: "none", fontSize: 14 }}
                  label="Updates"
                />
                <Tab
                  sx={{ textTransform: "none", fontSize: 14 }}
                  label="New matter"
                />
                <Tab
                  sx={{ textTransform: "none", fontSize: 14 }}
                  label="Process"
                />
              </Tabs>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TopBar;
