import axios from "axios";
import Cookies from "universal-cookie";

const getNotes = async (fileId: number) => {
  try {
    const response = await axios.get(
      `https://ifa-uat.novex.software/notes?ifa=${new Cookies().get(
        "username"
      )}&file_id=${fileId}`,
      {
        headers: {
          Authorization: `Bearer ${new Cookies().get("portal-token")}`,
        },
      }
    );

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export default getNotes;
