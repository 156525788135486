import axios from "axios";
import Cookies from "universal-cookie";

const ifaApiUrl =
  process.env.REACT_APP_IFA_API_URL || "https://ifa-uat.novex.software";

const presignUpload = async (
  fileId: string,
  name: string,
  contentType: string
): Promise<any> => {
  const token = new Cookies().get("portal-token");

  try {
    const response = await axios.post(
      `${ifaApiUrl}/presign-upload?name=${name}`,
      {
        file_id: fileId,
      },
      {
        headers: {
          "Content-Type": contentType,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

export default presignUpload;
