import axios from "axios";
import Cookies from "universal-cookie";

const getUpdates = async () => {
  try {
    const token = new Cookies().get("portal-token");

    const response = await axios.post(
      "https://api.portal.equilaw.uk.com/Pusher/GetPastEvents",
      { token: token }
    );

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export default getUpdates;
