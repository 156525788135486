import React, { useEffect } from "react";
import "./App.css";
import { Box } from "@mui/material";
import Login from "./Components/Login/component";
import { BrowserRouter } from "react-router-dom";
import TopBar from "./Components/TopBar/component";
import Cookies from "universal-cookie";
import Cases from "./Components/Cases/component";

function App() {
  const [loggedIn, setLoggedIn] = React.useState<boolean>(false);
  const [username, setUsername] = React.useState<string>(" ");

  useEffect(() => {
    const token = new Cookies().get("portal-token");
    console.log(token);
    if (token) {
      setLoggedIn(true);
    }

    const username = new Cookies().get("username");
    if (username) {
      setUsername(username);
    }
  }, []);

  const loggedInHandler = (loggedIn: boolean, username: string) => {
    setLoggedIn(loggedIn);
    setUsername(username);
    new Cookies().set("username", username);
  };

  return (
    <BrowserRouter>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Material+Icons|Roboto:400,500,700|Source+Code+Pro"
      />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/gh/mlaursen/react-md@5.1.5/themes/react-md.teal-pink-200-light.min.css"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <Box>
        {loggedIn ? (
          <Box minHeight="100vh" bgcolor="#E6EBED">
            <TopBar username={username} />
            <Box marginTop={10} padding={10}>
              <Cases />
            </Box>
          </Box>
        ) : (
          <Box>
            <Login login_Handler={loggedInHandler} />
          </Box>
        )}
      </Box>
    </BrowserRouter>
  );
}

export default App;
